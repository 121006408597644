<template>
  <div class="container" @click="closeAll">
    <div class="contBox">
      <div class="tabBox">
        <div
          class="list"
          v-for="(item, index) in tabList"
          :key="index"
          @click="toggle(index)"
        >
          <img :src="curIndex == index ? item.src1 : item.src" alt="" />
        </div>
      </div>
      <div class="userBox">
        <div class="userName" @click.stop="tabToggle">
          <div class="name">您好！莫干山管理局</div>
          <div class="icon">
            <img src="../assets/image/jht-icon-08-1.png" alt="" />
          </div>
          <div class="userTabBox" v-if="tabIsShow">
            <div class="list">莫干山管理局</div>
            <div class="list" @click="tuichu">退出</div>
          </div>
        </div>
        <div class="shezhi" @click="toshezhi(setUrl)">
          <div class="icon">
            <img src="../assets/image/jht-icon-09-1.png" alt="" />
          </div>
        </div>
        <div class="pifu" @click="qiehuan">
          <div class="icon">
            <img src="../assets/image/jht-icon-10-1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="listBox" :class="list.length < 5 ? 'listsBox' : ''">
        <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          @click="toPage(item)"
        >
          <div
            class="winBox"
            v-if="
              (item.id == curId && item.id == '11601') ||
              (item.id == curId && item.id == '11602')
            "
          >
            <div class="erweima">
              <img :src="erweimaUrl" alt="" />
            </div>
            <div class="close" @click.stop="curId = '-1'">
              <img src="../assets/image/jht-icon-35.png" alt="" />
            </div>
          </div>
          <img :src="urlResource + item.icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mes from "@/components/message.vue";
import Api from "@/server";
export default {
  components: {
    mes,
  },
  data() {
    return {
      urlResource: "https://digital.mgsfjq.com/",
      tabList: [
        {
          src: require("../assets/image/jht-icon-13.png"),
          src1: require("../assets/image/jht-icon-13-act.png"),
        },
        {
          src: require("../assets/image/jht-icon-14.png"),
          src1: require("../assets/image/jht-icon-14-act.png"),
        },
        {
          src: require("../assets/image/jht-icon-15.png"),
          src1: require("../assets/image/jht-icon-15-act.png"),
        },
      ],
      curIndex: 0,
      list: [],
      setUrl: "",
      erweimaUrl: "",
      curId: "-1",
      tabIsShow: false,
    };
  },
  methods: {
    toggle(index) {
      this.curIndex = index;
      this.curId = "-1";
      this.hasLogin();
    },
    toPage(e) {
      if (e.id) {
        this.curId = e.id;
        if (this.curId == "11601") {
          e.url = "";
          this.erweimaUrl = "https://digital.mgsfjq.com/" + e.iconActive;
        } else if (this.curId == "11602") {
          e.url = "";
          this.erweimaUrl = "https://digital.mgsfjq.com/" + e.iconActive;
        }
      }
      if (e.url) {
        window.open(e.url);
      }
    },
    tabToggle() {
      this.tabIsShow = !this.tabIsShow;
    },
    closeAll() {
      this.tabIsShow = false;
    },
    toshezhi(url) {
      window.open(url);
    },
    qiehuan() {
      this.$router.push("/index");
    },
    tuichu() {
      this.$cookies.set("token", null);
      this.$cookies.set("uid", null);
      this.$router.push("/#/");
    },
    // 验证登录是否过期
    hasLogin() {
      // Api.ajaxRequest("auth/query/system/permission").then((res) => {
      //   let { code, data } = res;
      //   if (code == "200") {
      //     let list = data;
      //     let powerArr = [];
      //     list.forEach((item, index) => {
      //       powerArr.push(item.systemId);
      //     });
      //     this.powerArr = powerArr;
      //   }
      // });
      let params = { systemId: "count" };
      Api.ajaxRequest("auth/query/permission", params).then((res) => {
        this.list = [];
        let { code, data } = res;
        if (code == "200") {
          let list = data;
          list.forEach((e, v) => {
            if (e.type == "按钮" && e.moduleId == "admin019") {
              this.setUrl = e.url;
            }
          });
          list[this.curIndex].child.forEach((item, index) => {
            if (item.type == "菜单") {
              this.list.push(item);
            }
          });
        } else if (code == "503") {
          this.$message.error("登录信息已过期,请重新登录");
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 3000);
        } else {
          this.$message.error(res.description);
        }
      });
    },
  },
  mounted() {
    this.hasLogin();
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url(.././assets/image/jht-icon-29.jpg);
  background-size: 100% 100%;
  padding: 56px 35px 20px;
  box-sizing: border-box;
  .contBox {
    width: 100%;
    height: 100%;
    background-image: url(.././assets/image/jht-icon-28.png);
    background-size: 100% 100%;
    padding-top: 204px;
    box-sizing: border-box;
    .tabBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .list {
        width: 260px;
        height: 88px;
        max-width: 260px;
        max-height: 88px;
        margin-right: 30px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .userBox {
      display: flex;
      align-items: center;
      position: absolute;
      right: 40px;
      top: 32px;
      .userName {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        .userTabBox {
          width: 100%;
          padding: 10px;
          background-color: #fff;
          position: absolute;
          left: 0;
          top: 30px;
          z-index: 1;
          .list {
            font-size: 13px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #666;
          }
          .list:hover {
            font-weight: bold;
            color: #4f5b37 !important;
          }
        }
        .name {
          color: #2db9e7;
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .shezhi {
        margin-left: 29px;
        display: flex;
        cursor: pointer;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 16px;
          color: #3d3d3d;
          margin-left: 9px;
        }
      }
      .pifu {
        display: flex;
        margin-left: 15px;
        cursor: pointer;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 16px;
          color: #3d3d3d;
          margin-left: 9px;
        }
      }
    }
    .listBox {
      width: 1138px;
      height: 357px;
      margin: 5% auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .list {
        margin-bottom: 20px;
        cursor: pointer;
        position: relative;
        width: 338px;
        height: 170px;
        img{
          width: 100%;
          height: 100%;
        }
        .winBox {
          width: 230px;
          height: 230px;
          position: absolute;
          right: -250px !important;
          top: -65px;
          background-image: url(../assets/image/jht-icon-37.png);
          background-size: 100% 100%;
          z-index: 11;
          .close {
            width: 38px;
            height: 38px;
            position: absolute;
            bottom: -50px;
            left: 0;
            right: 0;
            margin: auto;
          }
          .erweima {
            width: 80%;
            height: 80%;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .listsBox {
      width: 937px !important;
      .list {
        width: 338px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .list:nth-child(1) {
        margin-left: 150px;
      }
      .list:nth-child(2) {
      }
      .list:nth-child(4) {
        margin-right: 150px;
      }
    }
  }
}

@media screen and (min-height: 1000px) {
}
</style>