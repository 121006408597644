<template>
  <div class="messageBox">
    <div class="mes-box" v-if="showMes && list1 && list1.length > 0">
      <div class="close" @click="openlist({ showMes: 'false' })">
        <img
          :src="
            type == '1'
              ? require('@/assets/images/fg_close.png')
              : require('@/assets/images/kj_close.png')
          "
          alt=""
        />
      </div>
      <div
        class="mes-item"
        v-for="(item, index) in list1"
        :key="index"
        :class="type == '1' ? 'mes1' : 'mes2'"
      >
        <div class="name" :title="item.actionDesc + '接口出现异常'">
          {{ item.actionDesc }}接口出现异常
        </div>
        <div class="time">{{ item.createDate }}</div>
        <div
          class="btn"
          :class="type == '1' ? 'btn1' : 'btn2'"
          @click.stop="openlist({ showMask: 'true' })"
        >
          查看
        </div>
      </div>
    </div>
    <div class="mask-box" v-if="showMask">
      <div class="table-box">
        <div class="closeMask" @click="openlist({ showMask: 'false' })"></div>
        <div class="table">
          <div class="table-item" v-for="(item, index) in list" :key="index">
            <div class="left">
              <div class="cercle"></div>
              <div class="txt">
                {{ item.actionDesc }}接口出现异常，请及时处理
              </div>
            </div>
            <div class="right">
              {{ item.createDate }}
            </div>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/server";
export default {
  props: ["type", "showMes", "showMask"],
  data() {
    return {
      currentPage: 1,
      pagesize: 10,
      page: 1,
      total: 1,
      list: [],
      list1: [],
      timer: null,
    };
  },
  methods: {
    getList1() {
      var date = new Date();
      var min = date.getMinutes();
      date.setMinutes(min - 5);
      let params = {
        data: {
          systemId: "datadirectory",
          createUid: Api.getUid(),
          moduleId: "datadirectory007001",
          actionResult: "fail",
        },
        page: 1,
        pagesize: 5,
        startDate: Api.formatDateTime(date),
        endDate: Api.formatDateTime(new Date()),
      };
      Api.ajaxRequest("log/query/page", params).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          let list = data.dataList;
          this.list1 = list;
        }
      });
    },
    getList() {
      let params = {
        data: {
          systemId: "datadirectory",
          createUid: Api.getUid(),
          moduleId: "datadirectory007001",
          actionResult: "fail",
        },
        page: this.page,
        pagesize: this.pagesize,
      };
      Api.ajaxRequest("log/query/page", params).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          this.total = Number(data.total);
          let list = data.dataList;
          this.list = list;
        }
      });
    },
    openlist(val) {
      this.$emit("val", val);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getList1();
    this.timer = setInterval(() => {
      this.getList1();
    }, 300000);
  },
  watch: {
    showMask() {
      if (this.showMask) {
        this.page = 1;
        this.getList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.messageBox {
  ::-webkit-scrollbar {
    display: none;
  }
  .mes-box {
    position: fixed;
    bottom: 16px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 10;
    .close {
      position: absolute;
      top: -30px;
      right: 0;
      > img {
        width: 24px;
        height: 24px;
      }
    }
    .mes-item {
      margin-top: 5px;
      width: 396px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      box-sizing: border-box;

      .name {
        font-size: 16px;
        color: #ffffff;
        max-width: 45%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time {
        font-size: 14px;
        color: #ffffff;
      }
      .btn {
        font-size: 14px;
        border-radius: 11px;
        padding: 0 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #7b4122;
        background-color: #ffffff;
      }
      .btn2 {
        color: #fff;
        background-color: #c29200;
      }
    }
    .mes1 {
      background-image: linear-gradient(-90deg, #ffdd80 0%, #ff00a0 100%),
        linear-gradient(#ae825d, #ae825d);
      background-blend-mode: normal, normal;
    }
    .mes2 {
      background-color: #856100;
      border: solid 1px #ff933c;
    }
  }
  .mask-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .table-box {
      margin-top: 100px;
      width: 706px;
      height: 70vh;
      background-color: #ffffff;
      border-radius: 12px;
      position: relative;
      padding: 30px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .closeMask {
        position: absolute;
        top: 0;
        right: -30px;
        width: 23px;
        height: 23px;
        background-image: url("../assets/images/close.png");
        background-size: 100% 100%;
        cursor: pointer;
      }
      .table {
        width: 100%;
        height: calc(100% - 50px);
        overflow: auto;
        .table-item {
          width: 100%;
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #f4f4f4;
          .left,
          .right {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #000000;
            .cercle {
              margin-right: 10px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #ca1e23;
            }
            .cercle1 {
              background-color: #b6b6b6;
            }
          }
          .text {
            color: #b6b6b6;
          }
        }
      }
      .page {
        height: 32px;
        /deep/.el-pager li,
        /deep/.btn-next {
          width: 32px !important;
          height: 32px !important;
        }
        /deep/.el-pager .number {
          line-height: 32px !important;
        }
      }
    }
  }
}
</style>
